import React, { useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";

const LeftArrow = (props) => {
  return (
    <div {...props} className="absolute top-[50%] z-50 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="29"
        viewBox="0 0 17 29"
        fill="none"
      >
        <path d="M16 28L2 14.5L16 1" stroke="white" strokeWidth="2" />
      </svg>
    </div>
  );
};
const RightArrow = (props) => {
  return (
    <div {...props} className="absolute top-[50%] right-0 cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="29"
        viewBox="0 0 17 29"
        fill="none"
      >
        <path d="M1 1L15 14.5L1 28" stroke="white" strokeWidth="2" />
      </svg>
    </div>
  );
};

export default React.forwardRef(({ onClose, gallery }, ref) => {
  const settings = {
    dots: false,
    infinite: true,
    autplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    arrows: true,
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black/60">
      <div className="relative">
        <div className="absolute cursor-pointer right-7 -top-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#fff"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </div>
        <div className="flex justify-center px-2 sm:p-5 sm:mx-5 my-14">
          <div className="relative w-full sm:w-2/3" ref={ref}>
            <Slider {...settings}>
              {gallery.map((item, index) => (
                <div className="relative px-8 h-[80vh]" key={index}>
                  {item?.isVideo ? (
                    <iframe
                      src={`https://player.vimeo.com/video/${
                        item?.videoUrl?.split("/")[
                          item?.videoUrl?.split("/")?.length - 1
                        ]
                      }?badge=0&amp;autopause=0&amp;autoplay=0&amp;player_id=0&amp;app_id=58479`}
                      frameBorder="0"
                      allow="fullscreen; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full"
                      title="Purple Gelato 5-9-2021"
                    ></iframe>
                  ) : (
                    <div className="flex justify-center h-full">
                      <GatsbyImage
                        image={item?.image?.asset?.gatsbyImageData}
                        alt="moment-banner"
                        objectFit="contain"
                      />
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
});
