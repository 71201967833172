import React, { useState, useRef } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Heading from "../components/common/heading";
import Layout from "../components/layout";
import NewsLetters from "../components/common/newsletter";
import ImageSliderPopup from "../components/common/popup/ImageSlider";
import Seo from "../components/common/SEO";

const Half = ({ moment, setGallery, setShow }) => {
  const handleClick = () => {
    if (moment?.gallery?.length > 0) {
      setGallery(moment?.gallery);
      setShow(true);
    }
  };
  return (
    <div
      className="col-span-12 cursor-pointer md:col-span-6"
      onClick={handleClick}
    >
      <div className="relative h-full group">
        <GatsbyImage
          image={moment.image?.asset.gatsbyImageData}
          alt="our-process"
          placeholder="blurred"
          layout="fullWidth"
          className="md:h-[438px] lg:h-full"
          width={1000}
          height={604}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end transition-opacity duration-500 opacity-0 group-hover:opacity-100 bg-black/50">
          <p className="text-white font-primary text-[25px] leading-[34px] max-w-[255px] pl-[37px] pb-[26px]">
            {moment?.caption}
          </p>
        </div>
      </div>
    </div>
  );
};

const Full = ({ moment, height, objectPosition, setGallery, setShow }) => {
  const handleClick = () => {
    if (moment?.gallery?.length > 0) {
      setGallery(moment?.gallery);
      setShow(true);
    }
  };
  return (
    <div className="col-span-12 cursor-pointer" onClick={handleClick}>
      <div
        className={`relative md:h-[${height}] group`}
        style={{ height: height }}
      >
        <GatsbyImage
          image={moment?.image?.asset.gatsbyImageData}
          alt="moment-banner"
          placeholder="blurred"
          className="w-full h-full"
          objectPosition={objectPosition}
          width={1000}
          height={604}
          // width={1000}
          // height={274}
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-end transition-opacity duration-500 opacity-0 group-hover:opacity-100 bg-black/50">
          <p className="text-white font-primary text-[25px] leading-[34px] max-w-[255px] pl-[37px] pb-[26px]">
            {moment?.caption}
          </p>
        </div>
      </div>
    </div>
  );
};

export default function Moments({ data }) {
  const { sanityMixMoments } = data;
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [gallery, setGallery] = useState([]);

  return (
    <Layout>
      <Seo
        title={sanityMixMoments?.seo?.metaTitle}
        description={sanityMixMoments?.seo?.metaDescription}
      />
      <Helmet>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Helmet>
      <div className="pt-[82px]">
        <div className="mix-container">
          <div className="flex flex-col items-center justify-center">
            <Heading
              title={sanityMixMoments?.title}
              caption={sanityMixMoments?.caption}
            />
            <div className="mt-[60px]">
              <div className="grid grid-cols-1 mt-5 md:grid-cols-12 md:gap-4">
                {[...sanityMixMoments?.moments].map((val, ind) =>
                  (ind + 1) % 3 === 0 ? (
                    (ind + 1) % 6 === 0 ? (
                      <Full
                        key={ind}
                        moment={val}
                        objectPosition="100% 70%"
                        height="274px"
                        setGallery={setGallery}
                        setShow={setShow}
                      />
                    ) : (
                      <Full
                        key={ind}
                        moment={val}
                        objectPosition="top"
                        height="274px"
                        setGallery={setGallery}
                        setShow={setShow}
                      />
                    )
                  ) : ind + 1 === sanityMixMoments?.moments.length &&
                    !(ind % 2 === 0) ? (
                    <Full
                      key={ind}
                      moment={val}
                      objectPosition="center"
                      height="500px"
                      setGallery={setGallery}
                      setShow={setShow}
                    />
                  ) : (
                    <Half
                      key={ind}
                      moment={val}
                      setGallery={setGallery}
                      setShow={setShow}
                    />
                  )
                )}
              </div>
              <div className="grid grid-cols-1 gap-4 mt-5 md:grid-cols-2"></div>
              <div className="mt-[58px] px-4 md:px-0">
                <p className="text-black font-primary text-[13px] font-medium text-center leading-[177.4%]">
                  {sanityMixMoments?.content}
                </p>
                <div className="mt-[59px] flex justify-center">
                  <button
                    className={`outline-none text-xs border-[1.5px] border-black w-[159px] leading-[177.4%] text-black font-primary font-medium tracking-[.21em] h-9
                  `}
                    onClick={() => navigate("/products")}
                  >
                    BUY FOR PARTY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[80px]">
          <NewsLetters small={true} />
        </div>
      </div>
      {show && (
        <ImageSliderPopup
          gallery={gallery}
          onClose={() => setShow(false)}
          ref={ref}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityMixMoments {
      title
      caption
      content
      moments {
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
        caption
        gallery {
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          isVideo
          videoUrl
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`;
